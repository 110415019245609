import { ApiSettings } from '@hultafors/shared/types';

import {
  CategoryPageLinkFragment,
  ContentPageLinkFragment,
} from '@hultafors/solidgear/types';

import { routes } from './constants';

export interface CreateDetailPageUrlParams {
  name: string;
  parentCategory: string;
  id: number | string;
  sku: string;
  settings: ApiSettings;
}

export const createDetailPageUrl = ({
  name,
  parentCategory,
  id,
  sku,
  settings,
}: CreateDetailPageUrlParams) => {
  // We cant fetch product without id, bail out
  if (!id) {
    return '/';
  }
  const DELIMITER = '-';
  let productSlug = slugify(name, DELIMITER);

  parentCategory = fixProductSlug(parentCategory, DELIMITER);
  const baseUrl = createBaseUrl(settings);
  // We can create url without these values, fake them.
  // Maybe remove this as this will make the detail page semi-work
  parentCategory = parentCategory ? parentCategory : 'parent-category';
  sku = sku ? sku : 'sku';
  productSlug = productSlug ? productSlug : 'product-name';
  return `${baseUrl}${routes.CATEGORY}/${parentCategory}/${sku}-${productSlug}/${id}`;
};

export const createGuidePageUrl = ({
  pageLink,
  url,
  settings,
}: {
  pageLink?: { slug: string };
  url?: string;
  settings: ApiSettings;
}) => {
  const baseUrl = createBaseUrl(settings);
  if (!pageLink && !url) {
    return '/';
  }
  if (pageLink) {
    return `${baseUrl}${routes.GUIDES}/${pageLink.slug}`;
  }

  if (url) {
    if (url.startsWith('/')) url = url.slice(1);
  }
  return `${baseUrl}${url}`;
};

export const createMenuItemUrl = ({
  pageLink,
  url,
  settings,
}: {
  pageLink?: ContentPageLinkFragment | CategoryPageLinkFragment | null;
  url?: string | null;
  settings: ApiSettings;
}): string => {
  const baseUrl = createBaseUrl(settings);
  if (!pageLink && !url) {
    return '/';
  }
  if (pageLink) {
    if (pageLink.__typename === 'CategoryPageRecord') {
      return `${baseUrl}${routes.CATEGORY}/${pageLink.slug}`;
    }
  }
  if (url) {
    if (url.startsWith('/')) url = url.slice(1);
  }
  return `${baseUrl}${url}`;
};

export const slugify = (value: string, delimiter = '-'): string => {
  if (!value) {
    return '';
  }
  const specialCharacters
    = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/,:;';
  const acceptedChars
    = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz_------';
  const p = new RegExp(specialCharacters.split('').join('|'), 'g');

  return (
    value
      .trim()
      .toLowerCase()
      .replace(/\s+/g, delimiter) // Replace spaces with -
      .replace(p, (c) => acceptedChars.charAt(specialCharacters.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\-]+/g, '') // Remove non word chars
      // eslint-disable-next-line no-useless-escape
      .replace(/\-\-+/g, delimiter)
  ); // Replace multiple dashes with single
};

export const fixProductSlug = (slug: string, delimiter = '-'): string => {
  if (slug) {
    const reg = new RegExp('_', 'g');
    return slug.trim().replace(reg, delimiter);
  }
  return '';
};

const createBaseUrl = (settings: ApiSettings) => {
  return settings?.language?.urlPrefix
    ? `/${settings?.language.urlPrefix}/`
    : '/';
};

export const createUrl = (url: string, settings: ApiSettings): string => {
  if (!url) {
    return '/';
  }
  if (url.startsWith('http')) {
    // directly render outgoing links
    return url;
  }
  // Make sure we take care of internal url:s with or without /
  if (url.startsWith('/')) {
    url = url.substr(1, url.length - 1);
  }

  const baseUrl = createBaseUrl(settings);
  // TODO fix slash if starting with slash or not
  return `${baseUrl}${url}`;
};

const createLink = (route: string, item: any, parent: any) => {
  const slug = item.pageLink ? item.pageLink.slug : item.url;
  if (parent) {
    const parentSlug = parent.pageLink ? parent.pageLink.slug : parent.url;
    return `/${route}/${parentSlug}/${slug}`;
  } else {
    return `/${route}/${slug}`;
  }
};

export const constructSubMenuUrl = (item: any, parent?: any) => {
  if (item) {
    // type of model
    const typeName = item?.pageLink?.__typename;

    switch (typeName) {
      case 'CategoryPageRecord':
        return createLink(routes.PRODUCTS, item, parent);
      case 'InformationListPageRecord':
      case 'InformationPageRecord':
        return createLink(routes.GUIDES, item, parent);
      default:
        return item.url;
    }
  }
  return '';
};

export const constructPlugUrl = (
  pageType: string,
  parentSlug?: string,
  slug?: string,
  link?: string,
): string | null => {
  if (link) {
    return link;
  } else if (parentSlug && slug) {
    return `${parentSlug}/${slug}`;
  } else if (slug) {
    switch (pageType) {
      case 'CampaignPageRecord':
        return `/${routes.CAMPAIGN}/${slug}`;
      case 'CategoryPageRecord':
        return `/${routes.CATEGORY}/${slug}`;
    }
    return `/${slug}`;
  } else {
    return null;
  }
};
